<template>
    <div class="relative" :class="{ 'pb-5': !errorMessage }">
        <label
            v-if="label"
            class="mb-2 block text-sm font-medium text-grey-600"
        >
            {{ label }}
            <span
                v-if="isRequired"
                title="required"
                class="text-sm font-normal text-[#FF5959]"
            >
                *
            </span>
        </label>
        <div class="form-group relative">
            <div
                :class="[
                    classes,
                    'flag',
                    errorMessage || error
                        ? '!focus:border-error !border-error'
                        : '!focus:border-bigg-yellow',
                ]"
            >
                <img
                    :src="`${staticUrl}/assets/icons/flag-thailand.svg`"
                    alt="flag"
                />
                <!-- <span>+66</span> -->
            </div>
            <input
                :id="name + '_id'"
                class="block w-full appearance-none rounded-lg p-2.5 focus:ring-0"
                :class="[
                    classes,
                    disabled ? 'bg-bigg-gray' : '',
                    errorMessage || error
                        ? 'border-error focus:border-error'
                        : 'border-[#ECECEC] focus:border-bigg-yellow',
                ]"
                autocomplete="off"
                :name="name"
                :type="type"
                :value="inputValue"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                @input="handleChange"
                @blur="handleBlur"
            />
            <slot name="func" :data="inputValue"></slot>
        </div>
        <div v-if="errorMessage" class="text-xs text-error">
            {{ $t(errorMessage) }}
        </div>
        <div v-if="error" class="text-xs text-error">
            {{ $t(error) }}
        </div>
    </div>
</template>
<script setup>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
const staticUrl = useRuntimeConfig().public.staticUrl
const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    enableTooltip: {
        type: Boolean,
        default: true,
    },
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    successMessage: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    classes: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        default: null,
    },
})
const name = toRef(props, 'name')
const theValue = toRef(props, 'value')

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    // meta,
} = useField(name, undefined, {
    initialValue: props.value,
})

watch(
    () => theValue.value,

    (newVal, _) => {
        if (newVal) {
            inputValue.value = newVal
        }
    }
)
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
<style lang="scss" scoped>
.form-group {
    display: flex;
    align-items: center;
    justify-content: center;
    .flag {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ececec;
        border-right: none;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        img {
            width: 26px;
            height: 26px;
            object-fit: contain;
            margin-right: 4px;
        }
        span {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
    }
    input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
@media (max-width: 1023px) {
    .group-input {
        background: #f6f7fa;
        > div > div {
            display: grid;
            grid-template-columns: 1fr 2.2fr 1fr;
            align-items: center;
            label {
                position: relative;
                order: 1;
                transform: unset;
                padding-left: 5px;
                top: 0;
                &:before {
                    content: unset;
                }
            }
            input {
                order: 2;
                background: none;
                border: none;
                text-align: right;
                padding: 0;
            }
        }
    }
}
input::placeholder {
    color: #b4b4b4;
}
</style>
