<template>
    <div class="relative">
        <label
            v-if="label"
            class="mb-2 block text-sm font-medium text-grey-600"
        >
            {{ label }}
            <span
                v-if="isRequired"
                title="required"
                class="text-sm font-normal text-[#FF5959]"
            >
                *
            </span>
        </label>
        <div class="relative pb-5">
            <!-- <div
                v-if="errorMessage && enableTooltip"
                :id="name + 'tooltip'"
                role="tooltip"
                class="absolute right-0 top-[-3px] z-20 inline-block -translate-y-full rounded-lg bg-grey-400 px-3 py-2 text-[10px] text-white shadow-sm"
            >
                {{ $t(errorMessage) }}
                <div class="tooltip-arrow" data-popper-arrow></div>
            </div> -->
            <input
                :id="name + '_id'"
                class="block w-full appearance-none rounded-lg bg-white p-2.5 focus:ring-0"
                :class="[
                    classes,
                    disabled ? 'bg-bigg-gray' : '',
                    errorMessage
                        ? 'border-error focus:border-error'
                        : 'border-[#ECECEC] focus:border-bigg-yellow',
                ]"
                autocomplete="off"
                :name="name"
                :type="type"
                :value="inputValue"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                @input="handleChange"
                @blur="handleBlur"
            />
            <div
                v-if="copy"
                class="absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <div
                    class="cursor-pointer rounded-2xl bg-bigg-yellow px-3 py-1 text-center text-xs font-normal italic text-black"
                    @click="handleCopy"
                >
                    {{ copied ? $t('common.copied') : $t('common.copy') }}
                </div>
            </div>
            <div
                v-if="confirmed"
                class="absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <img
                    :src="`${
                        useRuntimeConfig().public.staticUrl
                    }/assets/img/icons/check.svg`"
                    alt="check_icon"
                />
            </div>
            <div
                v-if="money"
                class="absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <div class="text-right text-sm font-medium text-purple-800">
                    {{ moneyValue }} MissingSymbol
                </div>
            </div>
        </div>
        <span v-if="errorMessage" class="absolute bottom-0 text-xs text-error">
            {{ $t(errorMessage) }}
        </span>
        <!-- <span class="text-red text-xs" v-show="errorMessage || meta.valid">
            {{ errorMessage || successMessage }}
        </span> -->
    </div>
</template>
<script setup>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
import { useClipboard } from '@vueuse/core'

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    enableTooltip: {
        type: Boolean,
        default: true,
    },
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    copy: {
        type: Boolean,
        default: false,
    },
    confirmed: {
        type: Boolean,
        default: false,
    },
    successMessage: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    classes: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    money: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
})
const name = toRef(props, 'name')
const theValue = toRef(props, 'value')

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    // meta,
} = useField(name, undefined, {
    initialValue: props.value,
})

watch(
    () => theValue.value,

    (newVal, _) => {
        if (newVal) {
            inputValue.value = newVal
        }
    }
)
const moneyValue = computed(() => {
    if (inputValue.value) {
        return new Number(inputValue.value * 1000).toLocaleString()
    } else {
        return 0
    }
})
const { copy: copyValue, copied, isSupported } = useClipboard()
const handleCopy = () => {
    if (inputValue.value && isSupported) {
        copyValue(inputValue.value)
    }
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
<style lang="scss" scoped>
@media (max-width: 1023px) {
    .group-input {
        background: #f6f7fa;
        > div > div {
            display: grid;
            grid-template-columns: 1fr 2.2fr 1fr;
            align-items: center;
            label {
                position: relative;
                order: 1;
                transform: unset;
                padding-left: 5px;
                top: 0;
                &:before {
                    content: unset;
                }
            }
            input {
                order: 2;
                background: none;
                border: none;
                text-align: right;
                padding: 0;
            }
        }
    }
}
</style>
