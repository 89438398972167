import * as Yup from 'yup'

export const verifyPhoneSchema = Yup.object().shape({
    phone: Yup.string()
        .required('error.required')
        .test('phonenumber-not-match', function (value) {
            if (/[^0-9]/.test(value)) {
                return this.createError({
                    message: 'error.phone_only_digits',
                })
            } else if (/^[1-9][0-9]*$/.test(value)) {
                return this.createError({
                    message: 'error.start_with_zero',
                })
            } else if (value.length < 10) {
                return this.createError({
                    message: 'error.min_10_digits',
                })
            } else if (value.length > 12) {
                return this.createError({
                    message: 'error.max_12_digits',
                })
            } else {
                return true
            }
        }),
    username: Yup.string()
        .required('error.required')
        .min(6, 'error.min_6_character')
        .max(30, 'error.max_30_character')
        .matches(/^[\u0E00-\u0E7Fa-zA-Z0-9_]+$/, 'error.alphanumeric_only'),
})
